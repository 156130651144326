<template>
	<article class="view-password-reset">
		<div class="site-wrapper rythm-v flex-row flex-center-v flex-center-h">

			<div class="flex-item--100 text-center padding">
				<h1>Configurer l'authentification</h1>
			</div>

			<div class="form-wrapper card shadow rythm-v"
					 style="flex-basis:40rem"
					 v-if="!passwordResetSuccess && !passwordResetToken">
				<h2>Mot de passe oublié ?</h2>
				<p><strong>Vous souhaitez configurer votre mot de passe ou l’authentification à deux facteur ?</strong></p>
				<p>
					Entrez l'adresse e-mail associée à votre compte pour recevoir un lien de réinitialisation.
				</p>

				<form @submit.prevent="requestPasswordReset"
							class="rythm-v">
					<div class="input-group">
						<label for="email">Votre adresse e-mail&nbsp;:</label>
						<input type="email"
									 name="email"
									 id="email"
									 v-model="email"
									 required />
					</div>

					<div class="input-group--controls flex-row flex-center">
						<button type="submit"
										class="button--primary">
							Réinitialiser le mot de passe
						</button>
					</div>
				</form>
			</div>

			<div class="form-wrapper card shadow rythm-v"
					 style="flex-basis:40rem"
					 v-if="!passwordResetSuccess && passwordResetToken">
				<h2>Choix du mot de passe</h2>

				<form @submit.prevent="resetPassword"
							class="rythm-v">
					<div class="input-group">
						<label for="password">Mot de passe
							<span class="small">(12 caractères minimum)</span></label>
						<input type="password"
									 name="password"
									 id="password"
									 v-model="resetPasswordData.password"
									 :invalid="passwordIsInvalid"
									 required />
					</div>

					<div class="input-group">
						<label for="password-check">Mot de passe <span class="small">(vérification)</span></label>
						<input type="password"
									 name="password-check"
									 id="password-check"
									 v-model="resetPasswordData.passwordCheck"
									 :invalid="passwordIsInvalid"
									 required />
					</div>

					<ul class="form-validations">
						<li v-for="(validate, index) in validations"
								:key="index">

							<IconCheckmark :class="validate.checked
								? 'icon icon-checkmark checked'
								: 'icon icon-checkmark'
								"></IconCheckmark>

							<span class="caption"
										v-html="validate.caption"></span>
						</li>
					</ul>

					<div class="input-group--controls flex-row flex-center">
						<button type="submit"
										class="button--primary"
										:disabled="!formIsComplete">
							<svg role="img"
									 class="icon icon-locker-closed"
									 xmlns="http://www.w3.org/2000/svg"
									 width="20"
									 height="22"
									 fill="none"
									 viewBox="0 0 20 22">
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M3 11C2.44772 11 2 11.4477 2 12V19C2 19.5523 2.44772 20 3 20H17C17.5523 20 18 19.5523 18 19V12C18 11.4477 17.5523 11 17 11H3ZM0 12C0 10.3431 1.34315 9 3 9H17C18.6569 9 20 10.3431 20 12V19C20 20.6569 18.6569 22 17 22H3C1.34315 22 0 20.6569 0 19V12Z"
                  clip-rule="evenodd"
                />
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M10 2C8.93913 2 7.92172 2.42143 7.17157 3.17157C6.42143 3.92172 6 4.93913 6 6V10C6 10.5523 5.55228 11 5 11C4.44772 11 4 10.5523 4 10V6C4 4.4087 4.63214 2.88258 5.75736 1.75736C6.88258 0.632141 8.4087 0 10 0C11.5913 0 13.1174 0.632141 14.2426 1.75736C15.3679 2.88258 16 4.4087 16 6V10C16 10.5523 15.5523 11 15 11C14.4477 11 14 10.5523 14 10V6C14 4.93913 13.5786 3.92172 12.8284 3.17157C12.0783 2.42143 11.0609 2 10 2Z"
                  clip-rule="evenodd"
                />
              </svg>
							<span class="caption">Enregistrer le mot de passe</span>
						</button>
					</div>
				</form>
			</div>

			<!-- Password reset success -->
			<div class="card shadow rythm-v"
					 style="flex-basis:40rem"
					 v-if="passwordResetSuccess">
				<h2>Votre mot de passe est enregistré !</h2>
				<p>
					Veuillez configurer l'authentification à deux facteurs si ça n'est déjà fait.
				</p>
			</div>


			<!-- TOTP SETUP -->
			<div class="card shadow rythm-v"
					 style="flex-basis:40rem"
					 v-if="passwordResetToken">
				<h2>
					Authentification à deux facteurs
				</h2>

				<div class="rythm-v"
						 v-if="!totp.qrCodeURL">
					<p><strong>Un code d'authentification à usage unique</strong> ("TOTP" ou "2FA") vous sera demandé pour vous connecter.</p>
					<p>Vous pouvez recevoir ce code sur <strong>une application mobile</strong> ou <strong>par e-mail</strong>.
					</p>

					<!-- reset totp -->
					<div class="input-group--controls flex-row flex-center">
						<button type="button"
										@click="resetTotpQrCode()"
										class="button--primary">
							Configurer l’authentification 2FA
						</button>
					</div>
				</div>

				<!-- Reconfigure TOTP -->
				<div class="flex-row flex-center rythm-v"
						 v-if="totp.qrCodeURL">

					<div class="flex-col flex-gap ">
						<radio-group class="radio-group--inline strong-label"
												 :radios="lists.totpMethod"
												 label="Comment souhaitez-vous recevoir le code ?"
												 id="2faMethod"
												 name="2faMethod"
												 v-model="totp.method"></radio-group>
					</div>

					<!-- TOTP APP -->
					<div class="flex-item--100"
							 v-if="totp.method === 'app'">
						<!-- <h3>Avec une application mobile</h3> -->
						<div>
							<ul class="rythm-v">
								<li>
									<strong>Utilisez ou installez l'application de votre choix</strong> sur votre smartphone <a href="https://play.google.com/store/search?q=TOTP&c=apps&hl=fr"
										 target="_blank">Android</a> ou <a href="https://www.apple.com/fr/search/TOTP?src=serp"
										 target="_blank">iOS</a>.
								</li>
								<li>
									<p><strong>Scannez le QR Code</strong> pour ajouter l'eCRF ADARIC à votre appareil :</p>
								</li>
							</ul>

							<div class="flex-row flex-center">
								<img :src="totp.qrCodeURL"
										 class="qrcode"
										 width="320"
										 height="320"
										 alt="TOTP QR Code">
							</div>


						</div>
					</div>

					<!-- TOTP EMAIL -->
					<div class="flex-item--100"
							 v-if="totp.method === 'email'">
						<!-- <h3>Par e-mail</h3> -->
						<div class="flex-row flex-center">
							<!-- <ul class="rythm-v">
								<li>
									<p>Demandez un code : <button type="button"
														class="button--outline"
														@click="sendTotpCode">Recevoir un code par e-mail</button></p>
								</li>
								<li>
									<p>et entrez le code ci-dessous pour le valider.</p>
								</li>
							</ul> -->

							<button type="button"
											class="button--outline"
											@click="sendTotpCode">
								<IconMailPlus></IconMailPlus>
								<span class="caption">Recevoir un code par e-mail</span>
							</button>
						</div>
					</div>


					<!-- CHECK TOTP -->
					<div class="card card--totp"
							 v-if="totp.method">
						<form @submit.prevent="checkTotpCode"
									class="rythm-v">

							<div class="totp-check-input">
								<div class="input-group">
									<label class="flex-noshrink"
												 for="totpCode"><strong>Code 2FA&nbsp;:</strong></label>
									<input type="text"
												 class="text-center totp-code-input"
												 name="totpCode"
												 id="totpCode"
												 autocomplete="one-time-code"
												 inputmode="numeric"
												 maxlength="6"
												 pattern="\d{6}"
												 placeholder="******"
												 v-model="totp.code"
												 required />
								</div>
								<div class="input-group--controls flex-row flex-center">
									<button type="submit"
													class="button--primary">
										<IconFingerprint></IconFingerprint>
										<span class="caption">Vérifier le code</span>
									</button>
								</div>
							</div>
						</form>
					</div>

				</div>

			</div>


		</div>
	</article>
</template>

<script>
import { emailIsValid } from "../libs/helpers";
import { EventBus } from "../event-bus.js";
import { checkPasswordChars } from '../libs/helpers.js';
import radioGroup from "@/components/radioGroup.vue";

import IconCheckmark from '../assets/svg/icon-checkmark.svg';
import IconFingerprint from '../assets/svg/icons/icon-fingerprint.svg';
import IconMailPlus from '../assets/svg/icons/icon-mail-plus.svg';

export default {
	name: "PasswordReset",
	components: {
		IconCheckmark, IconFingerprint, IconMailPlus, radioGroup
	},

	// Route parameter
	props: ["userProfileID", "passwordResetToken"],

	data: function () {
		return {
			// Email verification
			verificationPending: false,
			verificationError: false,
			verificationComplete: false,

			// Password change
			passwordResetPending: false,
			passwordResetSuccess: false,
			email: null,

			resetPasswordData: {
				password: "",
				passwordCheck: "",
			},

			// TOTP
			totp: {
				qrCodeURL: '',
				code: '',
				validates: null,
				reset: false,
				method: ''
			},
			lists: {
				"totpMethod": [
					{
						label: "Avec une application mobile",
						value: "app"
					},
					{
						label: "Par e-mail",
						value: "email"
					}
				]
			},
		};
	},


	methods: {

		sendTotpCode: function () {
			let token = this.passwordResetToken;
			if (!token) return;

			const payload = {
				token: token
			};

			this.$toasted.clear();

			this.$store
				.dispatch("SEND_TOTP_CODE", payload)
				.then((data) => {
					this.$toasted.global.appSuccess({
						message: "<strong>Code envoyé !</strong> Consultez votre boîte de réception et validez votre code 2FA.",
					});
				})
				.catch((error) => {
					let message = "Une erreur est survenue";

					if (error.status === 403) {
						message = "Impossible d'envoyer le code 2FA, ce lien de réinitialisation n'est plus valide.";
					}

					if (error.status === 429) {
						message = "Veuillez patienter quelques instants pour demander un nouveau code.";
					}

					this.$toasted.global.appError({ message: message });
				});

			setTimeout(() => {
				this.$toasted.clear();
			}, 3000)
		},

		checkTotpCode: function () {
			let token = this.passwordResetToken;
			let code = this.totp?.code;
			let mode = this.totp?.method;
			if (!token || !code || !mode) return;

			const payload = {
				token: token,
				code: code,
				mode: mode
			};

			this.$store
				.dispatch("CHECK_TOTP_CODE", payload)
				.then(() => {
					this.totp.validates = true;

					if (this.userProfileID && !this.passwordResetSuccess) {
						this.$toasted.global.appSuccess({ message: "<strong>Code valide !</strong> Configurez votre mot de passe." });
						return;
					}
					else {
						this.$toasted.global.appSuccess({ message: "<strong>Code valide !</strong> Vous pouvez vous connecter." });
						this.$router.push("/");
						return;
					}

				})
				.catch((error) => {
					this.totp.code = '';
					this.totp.validates = false;

					this.$toasted.global.appError({ message: "Code 2FA invalide" });
				});
		},

		// Get the user's TOTP provisioning QR Code
		resetTotpQrCode: function () {

			// Exisiting password or 2FA reset
			if (!this.userProfileID) {
				if (!window.confirm("Réinitialiser l'authentification 2FA ?")) return false;
			}

			let token = this.passwordResetToken;
			if (!token) return '';

			const payload = {
				token: token,
			};

			this.$store
				.dispatch("RESET_TOTP_CODE", payload)
				.then((qrcode) => {
					this.totp.qrCodeURL = qrcode;
				})
				.catch((error) => {
					this.totp.qrCodeURL = '';
					this.$toasted.global.appError({
						message: "Impossible de réinitialiser l’authentification 2FA !",
					});
				});
		},

		requestPasswordReset: function (e) {
			this.$toasted.clear();
			let email = this.email;

			if (!this.validateEmail(email)) {
				return this.$toasted.global.appError({
					message: "L'adresse e-mail est invalide",
				});
			}

			this.$store
				.dispatch("REQUEST_PASSWORD_RESET", email)
				.then(() => {
					EventBus.$emit(
						"trackEvent",
						"Réinitialisation mot de passe",
						"Demande initiale : envoyée"
					);
					this.$toasted.global.appSuccess({
						message:
							"Consultez votre boîte de réception, un lien vous a été envoyé pour configurer votre mot de passe.",
					});
				})
				.catch((response) => {

					// Either the user and email doesn't match or the user is inactive or nonexistent
					if (response.status === 403) {
						this.$toasted.global.appError({
							message: "Cette adresse ne correspond pas à un utilisateur.",
						});
					}

					// Email is invalid
					else if (response.status === 422) {
						this.$toasted.global.appError({
							message:
								"Une erreur est survenue, vérifiez votre adresse e-mail.",
						});
					}

					// Email sending is delayed (too many requests)
					else if (response.status === 429) {
						this.$toasted.global.appError({
							message:
								"Veuillez patienter avant de faire une nouvelle demande.",
						});
					}

					// Cannot send email, server error, …
					else {
						this.$toasted.global.appError({
							message:
								"Une erreur est survenue lors de l'envoi de l'email d'activation, veuillez nous contacter.",
						});
					}
				});
		},

		validateEmail: function (email) {
			return emailIsValid(email);
		},

		// Update Email
		// NOTE: Get the `userProfileID` and `passwordResetToken` parameters from the component's props (or this.$route.params.userProfileID)
		verifyEmail: function () {
			// Display loader
			this.verificationPending = true;
			this.verificationError = false;
			this.verificationComplete = false;

			this.$store
				.dispatch("VERIFY_EMAIL", {
					userID: this.userProfileID,
					token: this.passwordResetToken,
				})
				.then((response) => {
					this.resetPasswordData.token = response.token;

					let toast = this.$toasted.global.appSuccess({
						message: "Votre adresse e-mail est vérifiée !",
					});
					setTimeout(function () {
						toast.goAway();
					}, 5000);

					this.verificationPending = false;
					this.verificationComplete = true;
				})
				.catch((error) => {
					this.verificationPending = false;
					this.verificationError = true;

					let msg =
						"Ce lien de validation a expiré, impossible de vérifier l'adresse e-mail.";

					this.$toasted.global.appError({
						message: msg,
					});
				});
		},

		// Reset the password
		resetPassword: function () {
			let payload = {
				...this.resetPasswordData,
				token: this.passwordResetToken,
			};

			this.$store
				.dispatch("RESET_PASSWORD", payload)
				.then(() => {
					EventBus.$emit(
						"trackEvent",
						"Réinitialisation mot de passe",
						"Réinitalisation : succès"
					);
					this.passwordResetSuccess = true;

					// Redirect to login if all is configured
					if (this.totp.validates) {
						this.$toasted.global.appSuccess({ message: "<strong>Mot de passe enregistré !</strong> Vous pouvez vous connecter." });
						this.$router.push("/");
					}

				})
				.catch((error) => {
					EventBus.$emit(
						"trackEvent",
						"Réinitialisation mot de passe",
						"Réinitalisation : erreur"
					);

					if (error?.data?.status === 422 && error?.data?.payload?.error === 'old-password-used') {
						this.$toasted.global.appError({ message: "Vous ne pouvez pas choisir un mot de passe utilisé précédemment." });
						return false;
					}
					else {
						this.passwordResetSuccess = false;
						this.passwordResetToken = null;

						this.$router.push("/password-reset");
						this.$toasted.global.appError({
							message:
								"Cette demande de réinitialisation a expirée, <br>demandez l’envoi d'un autre e-mail.",
						});
					}
				});
		},
	},

	computed: {
		formIsComplete: function () {
			let trues = 0;
			this.validations.forEach((el) => {
				if (el.checked) trues++;
			});
			return trues === this.validations.length;
		},

		validations: function () {
			return [
				{
					type: "passwordStrength",
					caption:
						'Le mot de passe comprend des majuscules, minuscules, chiffres et au moins un des caractères suivants : <code>#?!@$%^&*-.</code>',
					checked: checkPasswordChars(this.resetPasswordData.password) !== null,
				},
				{
					type: "passwordLength",
					caption: "La longueur du mot de passe est de 12 caractères minimum.",
					checked: this.resetPasswordData.password.length >= 12,
				},
				{
					type: "passwordMatch",
					caption: "Les mots de passe correspondent.",
					checked:
						this.resetPasswordData.password != "" &&
						this.resetPasswordData.passwordCheck != "" &&
						this.resetPasswordData.password ===
						this.resetPasswordData.passwordCheck,
				},
			];
		},

		passwordIsInvalid: function () {
			// No data
			if (
				this.resetPasswordData.password === "" &&
				this.resetPasswordData.passwordCheck === ""
			)
				return false;

			let passwordLength = this.validations.find(
				(el) => el.type === "passwordLength"
			);

			let passwordMatch = this.validations.find(
				(el) => el.type === "passwordMatch"
			);

			let passwordStrength = this.validations.find(
				(el) => el.type === "passwordStrength"
			);

			return !passwordStrength.checked || !passwordLength.checked || !passwordMatch.checked;
		},
	},

	mounted() {
		console.log(this.passwordResetToken, this.userProfileID);
		if (this.passwordResetToken && this.userProfileID) this.verifyEmail();
	},
};
</script>


<style lang="scss" scoped>
@import "~@/scss/common-views.scss";

.card--totp {
	margin-top: 2rem;
	max-width: 20rem;
}

:deep(.radio-group--2faMethod) {
	.label {
		flex-basis: 100%;
		font-weight: 500;
	}
}

.totp-check-input {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: center;
	gap: .5rem;
	flex-basis: 100%;

	.input-group--controls,
	input,
	button {
		flex-basis: 100%;
	}

	label[for="totpCode"] {
		display: none;
	}

	button {
		justify-content: center;
		align-items: center;
	}
}

.icon-checkmark .icon-checked {
	opacity: 0;
	fill: #3aaa35;
}

.icon-checkmark .icon-unchecked {
	fill: #95c11f;
	opacity: 1;
}

.icon-checkmark.checked .icon-checked {
	opacity: 1;
}

.icon-checkmark.checked .icon-unchecked {
	opacity: 0;
}

::v-deep .caption code {
	letter-spacing: .5ch;
}
</style>
